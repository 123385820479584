export default class Router {
    constructor(authManager, spotify) {
        this.currentPage = null;
        this.authManager = authManager;
        this.spotify = spotify;
        this.pages = {
            home: () => import("./pages/HomePage").then((m) => new m.default(this.authManager, this.spotify)),
            me: () => import("./pages/MePage").then((m) => new m.default(this.authManager, this.spotify)),
            callback: () => import("./pages/CallbackPage").then((m) => new m.default(this.authManager, this.spotify)),
            artist: (params) => import("./pages/ArtistPage").then((m) => new m.default(this.authManager, this.spotify, params?.id)),
            track: (params) => import("./pages/TrackPage").then((m) => new m.default(this.authManager, this.spotify, params?.id)),
        };
    }
    init() {
        window.addEventListener("popstate", this.handlePopState.bind(this));
        this.navigateTo(this.getCurrentPage());
    }
    getCurrentPage() {
        const hash = window.location.hash.slice(1);
        const [pageName] = hash.split("?");
        return pageName || "home";
    }
    getParams(hash) {
        const params = {};
        const [, queryString] = hash.split("?");
        if (queryString) {
            const pairs = queryString.split("&");
            pairs.forEach((pair) => {
                const [key, value] = pair.split("=");
                console.log(`Key: ${key}, Value: ${value}`);
                params[key] = decodeURIComponent(value);
            });
        }
        return params;
    }
    async navigateTo(pageName, params) {
        console.info(`Navigating to ${pageName}`);
        if (!["home", "callback"].includes(pageName) && !this.authManager.isAuthentified()) {
            console.error("Authentification requise pour accéder à la page", pageName);
            this.navigateTo("home", { error: "Authentification requise pour accéder à la page" });
            return;
        }
        if (pageName === "callback") {
            if (!window.location.search.includes("code")) {
                console.error("La page 'callback' ne peut pas être atteinte directement");
                this.navigateTo("home", { error: "La page 'callback' ne peut pas être atteinte directement" });
                return;
            }
        }
        else {
            if (!this.pages[pageName]) {
                console.error(`Page "${pageName}" not found`);
                return;
            }
        }
        let url = `#${pageName}`;
        if (params) {
            const queryParams = new URLSearchParams(params).toString();
            url += `?${queryParams}`;
        }
        history.pushState(null, "", url);
        await this.renderPage(pageName, params);
    }
    handlePopState() {
        const pageName = this.getCurrentPage();
        const params = this.getParams(window.location.hash.slice(1));
        this.renderPage(pageName, params);
    }
    async renderPage(pageName, params) {
        const pageLoader = this.pages[pageName];
        if (pageLoader) {
            if (this.currentPage) {
                this.currentPage.container.innerHTML = "";
            }
            this.currentPage = await pageLoader(params);
            this.currentPage.render();
        }
        else {
            console.error(`Page "${pageName}" not found`);
        }
    }
}
