export var Genre;
(function (Genre) {
    Genre[Genre["acoustic"] = 0] = "acoustic";
    Genre[Genre["afrobeat"] = 1] = "afrobeat";
    Genre[Genre["alt-rock"] = 2] = "alt-rock";
    Genre[Genre["alternative"] = 3] = "alternative";
    Genre[Genre["ambient"] = 4] = "ambient";
    Genre[Genre["anime"] = 5] = "anime";
    Genre[Genre["black-metal"] = 6] = "black-metal";
    Genre[Genre["bluegrass"] = 7] = "bluegrass";
    Genre[Genre["blues"] = 8] = "blues";
    Genre[Genre["bossanova"] = 9] = "bossanova";
    Genre[Genre["brazil"] = 10] = "brazil";
    Genre[Genre["breakbeat"] = 11] = "breakbeat";
    Genre[Genre["british"] = 12] = "british";
    Genre[Genre["cantopop"] = 13] = "cantopop";
    Genre[Genre["chicago-house"] = 14] = "chicago-house";
    Genre[Genre["children"] = 15] = "children";
    Genre[Genre["chill"] = 16] = "chill";
    Genre[Genre["classical"] = 17] = "classical";
    Genre[Genre["club"] = 18] = "club";
    Genre[Genre["comedy"] = 19] = "comedy";
    Genre[Genre["country"] = 20] = "country";
    Genre[Genre["dance"] = 21] = "dance";
    Genre[Genre["dancehall"] = 22] = "dancehall";
    Genre[Genre["death-metal"] = 23] = "death-metal";
    Genre[Genre["deep-house"] = 24] = "deep-house";
    Genre[Genre["detroit-techno"] = 25] = "detroit-techno";
    Genre[Genre["disco"] = 26] = "disco";
    Genre[Genre["disney"] = 27] = "disney";
    Genre[Genre["drum-and-bass"] = 28] = "drum-and-bass";
    Genre[Genre["dub"] = 29] = "dub";
    Genre[Genre["dubstep"] = 30] = "dubstep";
    Genre[Genre["edm"] = 31] = "edm";
    Genre[Genre["electro"] = 32] = "electro";
    Genre[Genre["electronic"] = 33] = "electronic";
    Genre[Genre["emo"] = 34] = "emo";
    Genre[Genre["folk"] = 35] = "folk";
    Genre[Genre["forro"] = 36] = "forro";
    Genre[Genre["french"] = 37] = "french";
    Genre[Genre["funk"] = 38] = "funk";
    Genre[Genre["garage"] = 39] = "garage";
    Genre[Genre["german"] = 40] = "german";
    Genre[Genre["gospel"] = 41] = "gospel";
    Genre[Genre["goth"] = 42] = "goth";
    Genre[Genre["grindcore"] = 43] = "grindcore";
    Genre[Genre["groove"] = 44] = "groove";
    Genre[Genre["grunge"] = 45] = "grunge";
    Genre[Genre["guitar"] = 46] = "guitar";
    Genre[Genre["happy"] = 47] = "happy";
    Genre[Genre["hard-rock"] = 48] = "hard-rock";
    Genre[Genre["hardcore"] = 49] = "hardcore";
    Genre[Genre["hardstyle"] = 50] = "hardstyle";
    Genre[Genre["heavy-metal"] = 51] = "heavy-metal";
    Genre[Genre["hip-hop"] = 52] = "hip-hop";
    Genre[Genre["holidays"] = 53] = "holidays";
    Genre[Genre["honky-tonk"] = 54] = "honky-tonk";
    Genre[Genre["house"] = 55] = "house";
    Genre[Genre["idm"] = 56] = "idm";
    Genre[Genre["indian"] = 57] = "indian";
    Genre[Genre["indie"] = 58] = "indie";
    Genre[Genre["indie-pop"] = 59] = "indie-pop";
    Genre[Genre["industrial"] = 60] = "industrial";
    Genre[Genre["iranian"] = 61] = "iranian";
    Genre[Genre["j-dance"] = 62] = "j-dance";
    Genre[Genre["j-idol"] = 63] = "j-idol";
    Genre[Genre["j-pop"] = 64] = "j-pop";
    Genre[Genre["j-rock"] = 65] = "j-rock";
    Genre[Genre["jazz"] = 66] = "jazz";
    Genre[Genre["k-pop"] = 67] = "k-pop";
    Genre[Genre["kids"] = 68] = "kids";
    Genre[Genre["latin"] = 69] = "latin";
    Genre[Genre["latino"] = 70] = "latino";
    Genre[Genre["malay"] = 71] = "malay";
    Genre[Genre["mandopop"] = 72] = "mandopop";
    Genre[Genre["metal"] = 73] = "metal";
    Genre[Genre["metal-misc"] = 74] = "metal-misc";
    Genre[Genre["metalcore"] = 75] = "metalcore";
    Genre[Genre["minimal-techno"] = 76] = "minimal-techno";
    Genre[Genre["movies"] = 77] = "movies";
    Genre[Genre["mpb"] = 78] = "mpb";
    Genre[Genre["new-age"] = 79] = "new-age";
    Genre[Genre["new-release"] = 80] = "new-release";
    Genre[Genre["opera"] = 81] = "opera";
    Genre[Genre["pagode"] = 82] = "pagode";
    Genre[Genre["party"] = 83] = "party";
    Genre[Genre["philippines-opm"] = 84] = "philippines-opm";
    Genre[Genre["piano"] = 85] = "piano";
    Genre[Genre["pop"] = 86] = "pop";
    Genre[Genre["pop-film"] = 87] = "pop-film";
    Genre[Genre["post-dubstep"] = 88] = "post-dubstep";
    Genre[Genre["power-pop"] = 89] = "power-pop";
    Genre[Genre["progressive-house"] = 90] = "progressive-house";
    Genre[Genre["psych-rock"] = 91] = "psych-rock";
    Genre[Genre["punk"] = 92] = "punk";
    Genre[Genre["punk-rock"] = 93] = "punk-rock";
    Genre[Genre["r-n-b"] = 94] = "r-n-b";
    Genre[Genre["rainy-day"] = 95] = "rainy-day";
    Genre[Genre["reggae"] = 96] = "reggae";
    Genre[Genre["reggaeton"] = 97] = "reggaeton";
    Genre[Genre["road-trip"] = 98] = "road-trip";
    Genre[Genre["rock"] = 99] = "rock";
    Genre[Genre["rock-n-roll"] = 100] = "rock-n-roll";
    Genre[Genre["rockabilly"] = 101] = "rockabilly";
    Genre[Genre["romance"] = 102] = "romance";
    Genre[Genre["sad"] = 103] = "sad";
    Genre[Genre["salsa"] = 104] = "salsa";
    Genre[Genre["samba"] = 105] = "samba";
    Genre[Genre["sertanejo"] = 106] = "sertanejo";
    Genre[Genre["show-tunes"] = 107] = "show-tunes";
    Genre[Genre["singer-songwriter"] = 108] = "singer-songwriter";
    Genre[Genre["ska"] = 109] = "ska";
    Genre[Genre["sleep"] = 110] = "sleep";
    Genre[Genre["songwriter"] = 111] = "songwriter";
    Genre[Genre["soul"] = 112] = "soul";
    Genre[Genre["soundtracks"] = 113] = "soundtracks";
    Genre[Genre["spanish"] = 114] = "spanish";
    Genre[Genre["study"] = 115] = "study";
    Genre[Genre["summer"] = 116] = "summer";
    Genre[Genre["swedish"] = 117] = "swedish";
    Genre[Genre["synth-pop"] = 118] = "synth-pop";
    Genre[Genre["tango"] = 119] = "tango";
    Genre[Genre["techno"] = 120] = "techno";
    Genre[Genre["trance"] = 121] = "trance";
    Genre[Genre["trip-hop"] = 122] = "trip-hop";
    Genre[Genre["turkish"] = 123] = "turkish";
    Genre[Genre["work-out"] = 124] = "work-out";
    Genre[Genre["world-music"] = 125] = "world-music";
})(Genre || (Genre = {}));
