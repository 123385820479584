import { mapToArtist, mapToAlbum } from "../models";
export function mapToTrack(track) {
    if (track.album) {
        return {
            id: track.id,
            name: track.name,
            duration: track.duration_ms,
            popularity: track.popularity,
            explicit: track.explicit,
            trackNumber: track.track_number,
            imageURL: new URL(track.album.images[0].url),
            trackURL: new URL(track.external_urls.spotify),
            previewURL: new URL(track.preview_url),
            album: mapToAlbum(track.album),
            artists: track.artists.map(mapToArtist),
        };
    }
    else {
        return {
            id: track.id,
            name: track.name,
            duration: track.duration_ms,
            explicit: track.explicit,
            trackNumber: track.track_number,
            imageURL: new URL(track.album.images[0].url),
            trackURL: new URL(track.external_urls.spotify),
            previewURL: new URL(track.preview_url),
            artists: track.artists.map(mapToArtist),
        };
    }
}
