export function mapToArtist(artist) {
    if (artist.genres) {
        return {
            id: artist.id,
            name: artist.name,
            popularity: artist.popularity,
            genres: artist.genres,
            followers: artist.followers.total,
            artistURL: new URL(artist.external_urls.spotify),
            pictureURL: new URL(artist.images[0].url),
        };
    }
    else {
        return {
            id: artist.id,
            name: artist.name,
            artistURL: new URL(artist.external_urls.spotify),
        };
    }
}
